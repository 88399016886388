import { createBrowserRouter } from "react-router-dom";
import { Home } from "../pages/home";
import { Talks } from "../pages/talks";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/talks",
    element: <Talks />,
  },
]);

import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Home } from "@mui/icons-material";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
  alwaysShow?: boolean;
}

interface OutProps {
  alwaysShow?: boolean;
}

function ElevationScroll(props: Props) {
  const { children, window, alwaysShow } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    sx: { background: trigger || alwaysShow ? "primary" : "transparent" },
  });
}

export default function ElevatedAppBar(props: OutProps) {
  const { alwaysShow } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 4 }}>
        Não cale sua voz
      </Typography>
      <Divider />
      <List>
        <ListItem>
          <Button component={Link} to="/" startIcon={<Home />}>
            O projeto
          </Button>
        </ListItem>
        <ListItem>
          <Button component={Link} to="/talks" startIcon={<NewspaperIcon />}>
            Matérias
          </Button>
        </ListItem>
        <ListItem>
          <Button
            component={Link}
            to="/contato"
            startIcon={<AlternateEmailIcon />}
          >
            Participe do projeto
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <ElevationScroll alwaysShow={alwaysShow}>
        <AppBar>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: "auto", display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: { sm: 1 } }}>
              <Link to="/">
                <IconButton color="secondary">
                  <VolunteerActivismIcon />
                </IconButton>
              </Link>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "flex" } }}>
              <Button component={Link} to="/" color="secondary">
                O projeto
              </Button>
              <Button component={Link} to="/talks" color="secondary">
                Matérias
              </Button>

              <Button component={Link} to="/contato" color="secondary">
                Participe do projeto
              </Button>
            </Box>
          </Toolbar>
          <nav>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "240px",
                  color: "primary.main",
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  );
}

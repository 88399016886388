import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import { pages } from "../../../content";
import { ScheduleNowButton } from "../../../components/buttons/ScheduleNowButton";
import { Link } from "react-router-dom";

export default function ContentSection() {
  return (
    <Box>
      {pages.map((page) => (
        <Box
          sx={{
            paddingX: { xs: 4, md: 16 },
            paddingY: 4,
            backgroundColor: page.backgroundColor,
          }}
        >
          <Divider
            sx={{
              width: 100,
              borderColor: page.textColor,
              marginBottom: 2,
            }}
          />
          <Typography
            variant="h3"
            sx={{ marginBottom: 4, color: page.textColor }}
          >
            {page.title}
          </Typography>
          <Box
            sx={{
              display: { md: "flex" },
              alignItems: { md: "center" },
            }}
          >
            <Box
              sx={{
                width: { md: page.paragraphImage ? "50%" : "100%" },
              }}
            >
              {page.paragraphs?.length ? (
                <Box
                  sx={{
                    paddingRight: { md: 4 },
                  }}
                >
                  {page.paragraphs?.map((paragraph) => (
                    <Typography
                      sx={{
                        paddingBottom: 2,
                        textAlign: "justify",
                        color: page.textColor,
                      }}
                    >
                      {paragraph}
                    </Typography>
                  ))}
                </Box>
              ) : null}
            </Box>

            {page.paragraphImage && (
              <Box
                sx={{
                  width: { md: "50%" },
                  marginLeft: { md: 6 },
                }}
              >
                <img alt="paragraph" src={page.paragraphImage} width="400px" />
              </Box>
            )}
          </Box>

          {page.items?.length ? (
            <Box sx={{ textAlign: "center" }}>
              <Box sx={{ display: { lg: "flex" }, width: "100%" }}>
                {page.items?.map((item) => (
                  <Paper
                    sx={{
                      backgroundColor: "primary.main",
                      paddingX: 4,

                      paddingY: 8,
                      color: "secondary.main",
                      flexGrow: 1,
                      width: "100%",
                      margin: { md: 2 },
                      marginBottom: { xs: 2, md: 0 },
                    }}
                  >
                    <Typography variant="h3" sx={{ marginBottom: 4 }}>
                      {item.title}
                    </Typography>

                    {item.paragraphs.map((paragraph) => (
                      <Typography>{paragraph}</Typography>
                    ))}
                  </Paper>
                ))}
              </Box>

              <Button
                variant="contained"
                sx={{ marginTop: 4 }}
                component={Link}
                to="/contato"
              >
                Participe do projeto
              </Button>
            </Box>
          ) : null}

          {page.conclusion && (
            <Typography sx={{ marginBottom: 4, color: "text.secondary" }}>
              {" "}
              {page.conclusion}
            </Typography>
          )}
          {page.action && (
            <Box sx={{ textAlign: { xs: "center", md: "initial" } }}>
              <ScheduleNowButton buttonText={page.action} />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}

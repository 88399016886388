import talk1Image from "./images/talk-1-image.jpeg";
import talk2Image from "./images/talk-2-image.jpeg";
import talk3Image from "./images/talk-3-image.jpeg";
import talk4Image from "./images/talk-4-image.jpeg";

type SubItem = {
  title: string;
  subTitle?: string;
  paragraphs: string[];
  icon?: any;
  price?: number;
  extra?: string;
};
type Page = {
  name: string;
  title: string;
  backgroundColor: string;
  textColor: string;
  paragraphs: string[];
  paragraphImage?: string;
  items?: SubItem[];
  conclusion?: string;
  action?: string;
  subSection?: Page[];
};

export const landingPageActionButtonMessage =
  "Olá! Gostaria de saber mais sobre o projeto Não Cale Sua Voz. Obrigado!";
export const landingPageActionButtonNumber = "5548988440011";
export const cellphone = "181";

export const pages: Page[] = [
  {
    name: "Sobre",
    title: "O projeto",
    backgroundColor: "#8377D1",
    textColor: "#FFFFFF",
    paragraphs: [
      "Sei a dor e a dificuldade que muitas mulheres possuem para saírem de relacionamentos abusivos, ou identificarem que algumas situações podem levá-las a serem mais um número no índice de feminicídio no nosso país.",
      'A violência contra a mulher independe de classe social e, ao meu ver, pode ser considerado uma "pandemia" de brutalidade.',
      "E é por esse motivo que eu Anna, resolvi me curar ( por já ter sido vítima de violência), por meio de um projeto que criei para auxiliar muitas mulheres para que despertem e fazermos, como sociedade, a reflexão sobre machismo, sociedade patriarcal, desigualdade de gênero e violência contra a mulher.",
      "Acredito que ações como essa, de ministrar palestras para reflexão, podem dar início a um olhar para nossa sociedade ainda patriarcal, haja visto que os relacionamentos abusivos estão acontecendo cada vez mais cedo.",
      "O intuito do meu projeto, é levar a reflexão da história da mulher no mundo, a violência que ainda existe, fazendo com que crianças, jovens e adultos percebam como as mulheres ainda precisam quebrar paradigmas, apesar de já terem conquistado muitas coisas.",
      "Além disso, o projeto conta hoje com profissionais que estão auxiliando voluntarimente mulheres vítimas de relacionamentos abusivos.",
      "Profissionais de todas as áreas podem se cadastrar ao projeto para poder fazer renascer essas mulheres, que hoje se encontram destruídas pela dor da violência.",
      "Nas agressões físicas o autor de violência,  geralmente,  agride a vítima na face, o que muitas vezes há quebras de dentes evidenciando as marcas da dor a todo momento, e a odontologia resgata o amor próprio.",
      "Para isso, a ideia é também trazer um dentista para poder restaurar os dentes dessas vítimas,  se assim tiverem sido quebrados, devolvendo a auto estima e o amor próprio, além de outros profissionais.",
    ],
  },
  {
    name: "Números em SC",
    title: "Santa Catarina",
    backgroundColor: "#F4F4F6",
    textColor: "#8377D1",
    paragraphs: [],
    items: [
      {
        title: "23.308",
        paragraphs: [
          "Medidas protetivas requeridas em Santa Catarina em 2022 (Fonte: TJSC)",
        ],
      },
      {
        title: "18.706",
        paragraphs: [
          "Medidas protetivas requeridas em Santa Catarina entre jan/ago de 2023 (Fonte: TJSC)",
        ],
      },
      {
        title: "56",
        paragraphs: ["Feminicídios em Santa Catarina em 2022 (Fonte: SSP/SC)"],
      },
      {
        title: "37",
        paragraphs: [
          "Feminicídios em Santa Catarina entre jan/ago de 2023 (Fonte: SSP/SC)",
        ],
      },
    ],
  },
];

export const talks: Page[] = [
  {
    name: "Matérias",
    title: "Matérias",
    backgroundColor: "#F4F4F6",
    textColor: "#8377D1",
    paragraphs: [],
    items: [
      {
        title:
          "“Olhei para minha dor, juntei os pedaços e tive forças para renascer”, entrevista exclusiva com Anna Paula Nienkötter",
        subTitle:
          "21/11/2022 06h 34min Atualizada em 21/11/2022 08h 55min Fonte: Produção Especial Portal Sou Catarina",
        paragraphs: [
          "Medidas protetivas requeridas em Santa Catarina em 2022 (Fonte: TJSC)",
        ],
        icon: talk1Image,
        extra:
          "https://www.soucatarina.com.br/noticias/758-olhei-para-minha-dor-juntei-os-pedacos-e-tive-forcas-para-renascer-entrevista-exclusiva-com-anna-paula-nienktter",
      },
      {
        title: "Projeto #naocaleasuavoz ganha espaço na TV e nas empresas",
        subTitle: "24/02/2023 15h 21min Atualizada em 24/02/2023 15h 29min",
        paragraphs: [
          "Medidas protetivas requeridas em Santa Catarina entre jan/ago de 2023 (Fonte: TJSC)",
        ],
        icon: talk2Image,
        extra:
          "https://www.soucatarina.com.br/noticias/1107-projeto-naocaleasuavoz-ganha-espaco-na-tv-e-nas-empresas",
      },
      {
        title:
          "Projeto na comarca de Camboriú dá voz a mulheres vítimas de violência doméstica",
        subTitle: "25 agosto 2021 | 15h24min",
        paragraphs: ["Feminicídios em Santa Catarina em 2022 (Fonte: SSP/SC)"],
        icon: talk3Image,
        extra:
          "https://www.tjsc.jus.br/web/imprensa/-/projeto-na-comarca-de-balneario-camboriu-da-voz-a-mulheres-vitimas-de-violencia-domestica",
      },
      {
        title:
          "Através das redes sociais, estagiária do PJSC divulga luta contra violência doméstica",
        subTitle: "25 novembro 2021 | 11h22min",
        paragraphs: [
          "Feminicídios em Santa Catarina entre jan/ago de 2023 (Fonte: SSP/SC)",
        ],
        icon: talk4Image,
        extra:
          "https://www.tjsc.jus.br/web/imprensa/-/atraves-das-redes-sociais-estagiaria-do-pjsc-divulga-luta-contra-violencia-domestica?redirect=%2F",
      },
    ],
  },
];

export const addresses = [
  {
    name: "Não cale sua voz",
    address: "R. Rui Barbosa, 811 - Agronômica, Florianópolis - SC, 88025-301",
    addressSecondLine: "6ª Delegacia de Polícia de Florianópolis",
    contact: "181 ou 190",
    website: "https://nãocalesuavoz.com.br",
  },
];

export const socialMedias = [
  {
    name: "phone",
    link: `tel:${cellphone}`,
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/direitoeinformacao10/",
  },
  {
    name: "whatsapp",
    link: `https://wa.me/${landingPageActionButtonNumber}?text=${landingPageActionButtonMessage}`,
  },
];

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
} from "@mui/material";
import { talks } from "../../../content";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";

export default function TalksSection() {
  return (
    <Box>
      {talks.map((talk) => (
        <Box
          sx={{
            paddingX: { xs: 4, md: 16 },
            paddingY: 4,
            backgroundColor: talk.backgroundColor,
            marginTop: 6,
          }}
        >
          <Divider
            sx={{
              width: 100,
              borderColor: talk.textColor,
              marginBottom: 2,
            }}
          />
          <Typography
            variant="h3"
            sx={{ marginBottom: 4, color: talk.textColor }}
          >
            {talk.title}
          </Typography>

          {talk.items?.map((item) => (
            <Card sx={{ display: { sm: "flex" }, marginBottom: 4 }}>
              <CardMedia
                component="img"
                sx={{ width: { sm: 300 } }}
                image={item.icon}
                alt="Live from space album cover"
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h5" color="primary">
                    {item.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="primary.light"
                    component="div"
                  >
                    {item.subTitle}
                  </Typography>
                </CardContent>
                {item.extra && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                  >
                    <Button
                      component={Link}
                      to={item.extra}
                      target="_blank"
                      endIcon={<OpenInNewIcon />}
                    >
                      Leia mais
                    </Button>
                  </Box>
                )}
              </Box>
            </Card>
          ))}
        </Box>
      ))}
    </Box>
  );
}

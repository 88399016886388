import React from "react";
import TalksSection from "./components/TalksSection";
import FooterSection from "../home/components/FooterSection";
import ElevatedAppBar from "../../components/navigation/ElevatedAppBar";

export const Talks = () => {
  return (
    <React.Fragment>
      <ElevatedAppBar alwaysShow={true} />
      <TalksSection />
      <FooterSection />
    </React.Fragment>
  );
};

import { Box, Typography } from "@mui/material";
import LandingSectionLayout from "./LandingSectionLayout";
import mainBackgroundImg from "../../../content/images/main-background-image.jpg";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

export default function LandingSection() {
  return (
    <LandingSectionLayout
      sxBackground={{
        backgroundImage: `url(${mainBackgroundImg})`,
        backgroundColor: "#7fc7d9", // Average color of the background image.
        backgroundPosition: "center",
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: "none" }}
        src={mainBackgroundImg}
        alt="increase priority"
      />
      <Typography sx={{ fontSize: { xs: 42 } }} variant="h2" color="secondary">
        #nãocalesuavoz
      </Typography>

      <Box sx={{ position: "absolute", bottom: 30, textAlign: "center" }}>
        <Typography>Saiba mais</Typography>
        <KeyboardDoubleArrowDownIcon />
      </Box>
    </LandingSectionLayout>
  );
}

import React from "react";
import FooterSection from "./components/FooterSection";
import ContentSection from "./components/ContentSection";
import LandingSection from "./components/LandingSection";
import ElevatedAppBar from "../../components/navigation/ElevatedAppBar";

export const Home = () => {
  return (
    <React.Fragment>
      <ElevatedAppBar />
      <LandingSection />
      <ContentSection />
      <FooterSection />
    </React.Fragment>
  );
};
